<template>
    <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
        <div class="vx-col">
            <div class="vx-row no-gutter justify-center items-center">
                <div class="vx-col lg:block">
                    <center>
                        <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
                    </center>
                </div>


                <div class="vx-col mx-auto lg:w-3/4">
                    <div class="p-8 login-tabs-container">
                        <div v-if="$store.state.portfolio">
                            <close-button></close-button>

                            <div class="grid justify-items-stretch">
                                <PieChartPlotly class=" justify-self-center"
                                    :pieData="getPortfolioWithPieChartDatav2()" />
                            </div>

                            <div v-for="(item) in getPortfolioWithPieChartDatav2()" :key="item.id"
                                class="grid grid-cols-5 gap-4 cursor-pointer"
                                @click="showDetail(item.wine_id, item.investment.id)">
                                <div>
                                    <span :style='{
                                        "background-color": item.color, "border-radius": "50%",
                                        "display": "inline-block"
                                    }' class="w-14 h-14 inline-block "></span>

                                </div>

                                <div class="col-span-3">
                                    <span class="oeno_portfolio_winetitle">{{
                                            item.item.wines.name
                                    }}</span><br />
                                    <span class="oeno_portfolio_winesubtitle">{{
                                            winary(item.item)
                                    }}</span><br />
                                    <span class="oeno_portfolio_winequantity">{{
                                            item.item.wines.winery.region.name
                                    }}</span>
                                </div>

                                <div>
                                    <span class="oeno_portfolio_price">{{
                                    
                                            getPercentageDiff(item.investment, item.item)
                                    
                                    }}
                                    </span>
                                    <br /><span :class="[
                                        priceUp(item.investment, item.item)
                                            ? 'oeno_portfolio_price_up'
                                            : 'oeno_portfolio_price_down',
                                    ]">{{ getChange(item.investment, item.item) }}</span>
                                </div>
                                <div class="col-span-5">
                                    <hr class="oeno_divider_sm" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <footer-bar />

        </div>
    </div>


</template>
  
<script>
import moment from "moment";
import PieChartPlotly from "../../../components/PieChartPlotly.vue";
import CloseButton from "../../../components/pageparts/CloseButton.vue"
import FooterBar from "../../../components/pageparts/Footer.vue"

export default {
    data() {
        return {
            colors: [
                "#aa57ea",
                "#ce7805",
                "#b384d2",
                "#ffeacc",
                "#ffcc7b",
                "#ce7805",
                "#fca527",
                "#e9ccff",
                "#520099",
                "#aa57ea",
                "#ce7805",
                "#b384d2",
                "#ffeacc",
                "#b384d2",
                "#ffcc7b",
                "#ce7805",
                "#fca527",
                "#e9ccff",
                "#520099",
                "#aa57ea",
                "#ce7805",
                "#b384d2",
                "#ffeacc",
                "#b384d2",
                "#ffcc7b",
                "#ce7805",
                "#fca527",
                "#e9ccff",
                "#520099",
                "#aa57ea",
                "#ce7805",
                "#b384d2",
                "#ffeacc",
                "#b384d2",
                "#ffcc7b",
                "#ce7805",
                "#fca527",
                "#e9ccff",
                "#520099",
            ],


        };
    },
    components: {
        PieChartPlotly,
        CloseButton,
        FooterBar
    },

    mounted() {

    },
    computed: {
        getTotals() {
            const values = [];

            this.$store.state.portfolio.forEach((investment) => {
                investment.items.forEach((item) => {
                    var itemPrice = this.getValuesForItem(investment, item);

                    var pos = -1;
                    for (let index = 0; index < values.length; index++) {
                        if (values[index].currency == itemPrice.currency) {
                            pos = index;
                        }
                    }

                    if (pos > -1) {
                        values[pos].total = values[pos].total + parseFloat(itemPrice.total);
                        values[pos].changePrice =
                            values[pos].changePrice + parseFloat(itemPrice.changePrice);
                    } else {
                        values.push({
                            currency: itemPrice.currency,
                            changePrice: parseFloat(itemPrice.changePrice),
                            total: itemPrice.total,
                        });
                    }
                });
            });
            return values;
        },


    },
    methods: {
        getPercentageDiff(investment, item) {
            return (
                (parseInt(this.getPrice(investment, item)) /
                    this.getTotals[0].total) *
                100
            ).toFixed(2) + "%";
        },
        getPortfolioWithPieChartDatav2() {

            let retValArray = [];
            let colorArray = [];
            let pos = 0;
            let coloPos = -1;

            this.$store.state.portfolio.forEach((investment) => {
                investment.items.forEach((item) => {

                    pos = pos + 1;

                    coloPos = colorArray.indexOf(item.wines.winery.region.name);
                    if (coloPos == -1) {
                        colorArray.push(item.wines.winery.region.name);
                        coloPos = colorArray.length - 1
                    }

                    retValArray.push({
                        investment: investment,
                        item: item,
                        color: this.colors[coloPos],
                        labels: item.wines.winery.region.name,
                        price: this.getPrice(investment, item),
                    })

                });
            });
            return retValArray;
        },

        closeButton() {
            history.go(-1)
        },


        showDetail(id, orderId) {
            this.$router.push({
                name: "page-portfolio-detail",
                params: { id: id, orderId: orderId },
            });
        },

        winary(wine) {
            var winary = "n/a";
            var vintage = "";

            if (wine.wines) {
                if (wine.wines.winery) {
                    vintage = wine.wines.vintage;
                    if (wine.wines.winery.name) {
                        winary = wine.wines.winery.name;
                    }
                }
            }

            winary = winary + " " + vintage;

            return winary;
        },


        getValuesForItem(investment, wine) {
            var isInLastSixMonths = "N";
            var orderDate = moment(investment.order_date);
            var sixMonthsAgo = moment().subtract(6, "months");
            if (sixMonthsAgo < orderDate) {
                isInLastSixMonths = "Y";
            }

            var total =
                parseInt(
                    parseFloat(
                        wine.quantity *
                        wine.wines.units_per_case *
                        wine.livePrices.data["price-average"]
                    ) * 100
                ) / 100;

            var buytotal =
                wine.quantity * wine.wines.units_per_case * wine.bottle_price;

            if (isInLastSixMonths == "Y") {
                if (total < buytotal) {
                    total = buytotal;
                }
            }

            var changePerc = ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(total)) * 100;
            var changePrice = parseFloat(total) - parseFloat(buytotal);

            return {
                changePerc: changePerc,
                changePrice: changePrice,
                total: total,
                currency: investment.currency,
            };
        },

        getPrice(investment, wine) {
            var priceValues = this.getValuesForItem(investment, wine);

            return priceValues.total;
        },



        getChange(investment, wine) {
            var priceValues = this.getValuesForItem(investment, wine);

            return this.currencyFormatter(priceValues.changePrice) + " ( " + this.currencyFormatter(priceValues.changePerc) + " %)";
        },

        priceUp(investment, wine) {
            var priceValues = this.getValuesForItem(investment, wine);

            if (priceValues.changePrice >= 0) {
                return true;
            } else {
                return false;
            }
        },

        currencyFormatter(value) {
            const options = {
                significantDigits: 2,
                thousandsSeparator: ",",
                decimalSeparator: ".",
                symbol: "",
            };

            if (typeof value !== "number") value = parseFloat(value);
            value = value.toFixed(options.significantDigits);

            const [currency, decimal] = value.split(".");
            return `${options.symbol} ${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                options.thousandsSeparator
            )}${options.decimalSeparator}${decimal}`;
        },
    },
};
</script>
 
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full bg-img vx-row no-gutter items-center justify-center mt-10",attrs:{"id":"page-login"}},[_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"vx-row no-gutter justify-center items-center"},[_c('div',{staticClass:"vx-col lg:block"},[_c('center',[_c('img',{staticClass:"logo mx-auto lg:w-1/4 mb-10",attrs:{"src":require("@/assets/logo.png"),"alt":"logo"}})])],1),_c('div',{staticClass:"vx-col mx-auto lg:w-3/4"},[_c('div',{staticClass:"p-8 login-tabs-container"},[(_vm.$store.state.portfolio)?_c('div',[_c('close-button'),_c('div',{staticClass:"grid justify-items-stretch"},[_c('PieChartPlotly',{staticClass:"justify-self-center",attrs:{"pieData":_vm.getPortfolioWithPieChartDatav2()}})],1),_vm._l((_vm.getPortfolioWithPieChartDatav2()),function(item){return _c('div',{key:item.id,staticClass:"grid grid-cols-5 gap-4 cursor-pointer",on:{"click":function($event){return _vm.showDetail(item.wine_id, item.investment.id)}}},[_c('div',[_c('span',{staticClass:"w-14 h-14 inline-block",style:({
                                    "background-color": item.color, "border-radius": "50%",
                                    "display": "inline-block"
                                })})]),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"oeno_portfolio_winetitle"},[_vm._v(_vm._s(item.item.wines.name))]),_c('br'),_c('span',{staticClass:"oeno_portfolio_winesubtitle"},[_vm._v(_vm._s(_vm.winary(item.item)))]),_c('br'),_c('span',{staticClass:"oeno_portfolio_winequantity"},[_vm._v(_vm._s(item.item.wines.winery.region.name))])]),_c('div',[_c('span',{staticClass:"oeno_portfolio_price"},[_vm._v(_vm._s(_vm.getPercentageDiff(item.investment, item.item))+" ")]),_c('br'),_c('span',{class:[
                                    _vm.priceUp(item.investment, item.item)
                                        ? 'oeno_portfolio_price_up'
                                        : 'oeno_portfolio_price_down',
                                ]},[_vm._v(_vm._s(_vm.getChange(item.investment, item.item)))])]),_vm._m(0,true)])})],2):_vm._e()])])]),_c('footer-bar')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-span-5"},[_c('hr',{staticClass:"oeno_divider_sm"})])
}]

export { render, staticRenderFns }